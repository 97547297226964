
// 各个页面的骨架屏配置

// 列表页通用
const productList = [
  {
    type: 'line'
  },
  {
    type: 'picNav'
  },

  {
    type: 'line'
  },

  {
    type: 'filter'
  },

  {
    type: 'cloudTag'
  },

  {
    type: 'goodsDouble',
  }
]

// 收藏页
const wishList = [
  {
    type: 'filter',
    style: {
      marginTop: '1.1733rem'
    }
  },

  {
    type: 'cloudTag'
  },

  {
    type: 'goodsDouble',
  }
]

// 收藏品类视图右侧商品列表
const wishListCategory = [
  {
    type: 'goodsDoubleCategory',
    number: 8
  }
]

// Me页面 - Wishlist/Recently Viewed
const mePageForWishlist = [
  {
    type: 'goodsDouble',

  }
]

// 店铺页
const storePage = [
  {
    type: 'filter'
  },

  {
    type: 'cloudTag'
  },

  {
    type: 'goodsDouble',

  }
]

// 凑单单列列表
const addItem = [
  {
    type: 'goodsOne',
    style: {
      paddingTop: '.32rem'
    }
  }
]

// 凑单双列列表
const addOnItem = [
  {
    type: 'goodsDouble'
  }
]

// 推荐页面
const recommendDrawer = [
  {
    type: 'line'
  },

  {
    type: 'filter'
  },

  {
    type: 'cloudTag'
  },

  {
    type: 'goodsDouble',

  }
]

// shein-picks
const sheinPicks = [
  {
    type: 'line'
  },
  {
    type: 'tab'
  },
  {
    type: 'filter'
  },

  {
    type: 'cloudTag'
  },

  {
    type: 'goodsDouble',

  }
]

const RecommendCommonNewCardTowRow = [
  {
    type: 'goodsDouble',

  }
]

const RecommendCommonOldCardTowRow = [
  {
    type: 'goodsDouble',

  }
]

// buybox弹窗
const buyboxCom = [
  {
    type: 'goodsOne',
  },
  {
    type: 'goodsOne',
  },
]
const orderConfirmationBuyInHand = [
  {
    type: 'goodsOne',
    number: 1
  }
]

export default {
  wishList,
  storePage,
  productList,
  addItem,
  recommendDrawer,
  sheinPicks,
  mePageForWishlist,
  addOnItem,
  RecommendCommonNewCardTowRow,
  RecommendCommonOldCardTowRow,
  buyboxCom,
  orderConfirmationBuyInHand,
  wishListCategory
}

